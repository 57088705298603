import React from 'react';
import DatePicker from "react-datepicker";
import './ContactUs.css';
import "react-datepicker/dist/react-datepicker.css";
import { xhr } from '../../config/axiosConfig';

export class ContactUs extends React.Component {
    constructor() {
        super()
        this.state = {
            name: "",
            email: "",
            telephone: "",
            arrivalDate: new Date(),
            location: "",
            aptType: "",
            lengthOfStay: "",
            monthlyBudget: "",
            employerName: "",
            pets: "",
            numPets: "",
            description: "",
            message: "",
        }
    }


    changeHandler = (event) => {
        const value = (event.target.name === "pets") ? event.target.id : event.target.value;

        this.setState({
            [event.target.name]: value
        });
    }

    handleDateChange = (date) => {
        console.log("date", date);
        this.setState({
            arrivalDate: date
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validate()) {
            xhr.post(`/contact/honeywell`, this.state)
                .then(response => {
                    console.log(response);
                    //this.setState({ideas: response.data})
                    alert("Thank you for contacting us!");
                })
                .catch(error => console.log(error));
        }

    }
    // homemade and dirty fast.
    validate = () => {

        if (this.state.name === "") {
            alert("Please provide your name!");
            document.myForm.name.focus();
            return false;
        }
        if (this.state.telephone === "") {
            alert("Please provide your telephone!");
            document.myForm.telephone.focus();
            return false;
        }
        if (this.state.email === "") {
            alert("Please provide your email!");
            document.myForm.email.focus();
            return false;
        }
        if (this.state.arrivalDate === "") {
            alert("Please provide your arrival date example 01/10/2020");
            document.myForm.arrivalDate.focus();
            return false;
        }
        if (this.state.location === "") {
            alert("Please provide your location!");
            document.myForm.location.focus();
            return false;
        }
        if (this.state.aptType === "") {
            alert("Please provide your apartment type!");
            document.myForm.aptType.focus();
            return false;
        }
        if (this.state.lengthOfStay === "") {
            alert("Please provide your length of stay!");
            document.myForm.lengthOfStay.focus();
            return false;
        }

        if (this.state.employerName === "") {
            alert("Please provide your Employer Name!");
            document.myForm.employerName.focus();
            return false;
        }
        console.log("this.state.pets", this.state.pets);
        if (this.state.pets === "") {
            alert("Please let us know about pets!");
            return false;
        }

        if (this.state.pets === "yes" && this.state.description === "") {
            alert("Please let us know about pet description!");
            document.myForm.description.focus();
            return false;
        }

        // if( document.myForm.EMail.value == "" ) {
        //    alert( "Please provide your Email!" );
        //    document.myForm.EMail.focus() ;
        //    return false;
        // }
        // if( document.myForm.Zip.value == "" || isNaN( document.myForm.Zip.value ) ||
        //    document.myForm.Zip.value.length != 5 ) {

        //    alert( "Please provide a zip in the format #####." );
        //    document.myForm.Zip.focus() ;
        //    return false;
        // }
        // if( document.myForm.Country.value == "-1" ) {
        //    alert( "Please provide your country!" );
        //    return false;
        // }
        return (true);
    }

    render() {
        return (
            <>
                <div className="col w-60">


                    <p>All field are required to submit.</p>
                    <form className="form p-2" id="myForm" name="myForm" onSubmit={this.handleSubmit} action="get">
                        <div className="form-group w-60">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Enter name" onChange={this.changeHandler} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="telephone">Telephone</label>
                            <input type="telephone" name="telephone" className="form-control" id="telephone" aria-describedby="telephoneHelp" placeholder="Enter phone" onChange={this.changeHandler} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" onChange={this.changeHandler} />

                        </div>
                        <div className="form-group">
                            <label htmlFor="arrivalDate">Arrival Date</label>
                            <br></br>
                            <DatePicker
                                className="form-control clickable input-md arrival-date"
                                selected={this.state.arrivalDate}
                                onChange={this.handleDateChange}
                                placeholder="&#xf133;  Check-In"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="location">Location</label>
                            <input type="text" name="location" className="form-control" id="location" aria-describedby="locationHelp" placeholder="Enter Location" onChange={this.changeHandler} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="aptType">Apartment Type</label>
                            <input type="text" name="aptType" className="form-control" id="aptType" aria-describedby="locationHelp" placeholder="Enter Apartment Type" onChange={this.changeHandler} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="lengthOfStay">Length of Stay</label>
                            <input type="text" name="lengthOfStay" className="form-control" id="lengthOfStay" aria-describedby="lengthOfStayHelp" placeholder="Enter Length of Stay" onChange={this.changeHandler} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="monthlyBudget">Monthly Budget</label>
                            <input type="text" name="monthlyBudget" className="form-control" id="monthlyBudget" aria-describedby="monthlyBudgetHelp" placeholder="Enter Monthly Budget" onChange={this.changeHandler} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="employerName">Employer Name</label>
                            <input type="text" name="employerName" className="form-control" id="employerName" aria-describedby="employerNameHelp" placeholder="Enter Employer's Name" onChange={this.changeHandler} />
                        </div>


                        <label className="pets-label">Do you Have Pets : </label><input type="radio" id="yes" name="pets" onChange={this.changeHandler} />
                        <label className="pets-label" htmlFor="male">Yes</label>
                        <input type="radio" id="no" name="pets" onChange={this.changeHandler} />
                        <label className="pets-label" htmlFor="female">No</label>

                        <div className="form-group">
                            <label htmlFor="numPets">Number of Pets</label>
                            <input type="text" name="numPets" className="form-control" id="numPets" aria-describedby="numPetsHelp" value={this.state.numPets} placeholder="Number of Pets" onChange={this.changeHandler} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Pet Description</label>
                            <input type="text" name="description" className="form-control" id="numPdescriptionets" aria-describedby="descriptionHelp" value={this.state.description} placeholder="Pet Description" onChange={this.changeHandler} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea name="message" className="form-control" placeholder="Message" value={this.state.message} onChange={this.changeHandler}></textarea>
                        </div>

                        <div className="form-group">
                            <input type="submit" className="btn-primary" value="Submit Query" />

                        </div>
                        <p className="footer">Please contact us toll free at 866-282-4750 or email us at info@nomadtemphousing.com for more information.</p>
                    </form>
                </div>
            </>
        );
    }
}

