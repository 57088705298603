import React from "react";

export class PaymentAndTerms extends React.Component<{}> {
    public render(): React.ReactNode {
        return (
            <>
            <h2>Payment and Terms?</h2>
            <p>If your company is not guaranteeing payment:</p>
            <ul>
                <li>Credit card payment will be required at the time of booking. You will be charged the first 30 days at time of rental. Following that, your card will be charged at 30 day intervals on subsequent due dates.</li>
                <li>At time of rental, Nomad will require a credit application, and rental application to be completed. The fee is $50.00. Depending on credit history, a security deposit may be required, or we may decline the rental.</li>
            </ul>
            <p>If Honeywell is guaranteeing rental payment:</p>
            <ul>
                <li>Credit card payment required. You will be charged for the first 30 days at time of rental. Following that, your card will be charged at 30 day intervals on subsequent due dates. </li>
                <li>No credit application or security deposit required.</li>
            </ul>
            <h2>General Terms</h2>
            <ul>
                <li>Rates are per day, not per night, as is the custom in the corporate apartment business.</li>
                <li>Please review additional terms and rental conditions on your rental agreement.</li>
            </ul>
            <h2>Pets</h2>
            <ul>
                <li>Please advise if you have pets of any type at time of inquiry. Many of our apartment communities allow pets, but not all of them.</li>
                <li>Restrictions on weight, dog breed and number of pets do apply, and vary widely based on location. Please note aggressive breed dogs are never allowed at any of our locations.</li>
                <li>Pet deposits, and pet fees, often apply. A pet fee does not remove obligations for any damage to apartment or property you/your pet may incur. Pet rent, as a daily fee, may also be charged. Policies vary by property.</li>
            </ul>
            </>
        );
    }
}