import React from "react";

export class EconomyAlternative extends React.Component<{}> {
    public render(): React.ReactNode {
        return (
            <>
            <h2>Economy Alternatives</h2>
            <p>The average rate of $190 per day for a temporary apartment may be more than you wish to spend. 
                The following are some potential alternatives that may be better suited to your needs. 
                Nomad is not affiliated with these options in any way, nor do we receive any income from 
                referring you to these organizations.
            </p>
           
            <h2>Extended Stay America</h2>
            <p>Hotels and motels can be a less expensive option. However, be aware they may provide 
                less square footage, amenities, and kitchen facilities.
            </p>
            <p>Extended Stay America brand hotels are often less expensive. 
                They provide studio style rooms without bedrooms. They have small kitchenettes, microwave ovens and refrigerators. They may work for a single person, 
                or a couple. They rent daily, weekly, and monthly. These are rated 2-star, when most corporate apartments are 4 or 5 star. Properties can be lower 
                quality and in varying condition. You will want to visit a location prior to booking. Nomad does not 
                use these properties as we have had mixed reviews from guests, however they do fit the bill as a cheaper 
                housing alternative.
            </p>
            <p>Other extended-stay brands with larger floor plans and bedrooms include Candlewood Suites, 
                Residence Inn, and Townplace Suites.
            </p>
           
            <h2>Airbnb</h2>
            <p>This internet-based booking site provides private accommodations to travelers. 
                You can share an accommodation with your landlord (say a spare bedroom), 
                or rent an entire apartment or even a home, all for yourself.
            </p>
            <p>Rates can be lower than professionally managed temporary apartments as most accommodations 
                are provided by amateur landlords. However, beware Airbnb does little vetting of the accommodation, 
                and you need to choose carefully. If you utilize this site, we suggest using 
                locations with multiple previous positive guest ratings, and that you carefully 
                read the fine print of the agreement. Understand the booking fees, limitations, 
                and even if the rental is legal. Just because a listing is noted, does not mean it 
                complies with local laws. For instance, most American cities prohibit residential 
                dwellings to be rented for less than 30 days. Educate yourself on this offering- it 
                may or may not be right for you.
            </p>
            
            </>
        );
    }
}