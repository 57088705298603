import React, { ChangeEvent } from "react";
import { ratesData } from "./RatesData";

export interface RentalRatesProps {
    readOnly?: boolean;
}

export interface RentalRatesState {
    currentIndex?: number;
}

export class RantalRates extends React.Component<RentalRatesProps,RentalRatesState> {
    
    public state: RentalRatesState = {
        currentIndex: 0
    }
    public render(): React.ReactNode {
        
        console.log("this.state.currentIndex", this.state.currentIndex );
        return (
            <>
                <h2>Corporate Apartment Rental Rate Ranges</h2>
                

                
                <p>In the United States and Canada, a 30 day minimum stay is required and local taxes may apply.</p>
                <p>If pets are permitted at your selected property, pet fees, deposits, and/or pet rent may apply. Breed and weight restrictions are often seen at the property level, as well.</p>
                <p>There is a credit card servicing fee of 4%.</p>
                <p>Rates vary by location, length of stay, season, and apartment type.</p>
                <div className="mt-1 mb-1">
                <label htmlFor="cars">Sample Locations, rate per 30 days:</label>
                <select 
                    className="form-control smallform"
                    id="cars" 
                    name="carlist" 
                    form="carform"
                    onChange={this.handleOptionsChange}
                    >
                {this.ratesAsOptions()}
                
                </select>
                {this.renderChossenStateOptions()}
                </div>
            </>
        );
    }

    protected handleOptionsChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({ currentIndex: event.currentTarget.selectedIndex }) 
    }

    protected renderStateItemData= (data: any) => {
        return data.items.map((item: any) => {
            return <div>{`City: ${item.city}`}
                        <ul>
                            <li>{`One Bedroom: ${item.one_bedroom}`}</li>
                            <li>{`Two Bedroom: ${item.two_bedroom}`}</li>
                            <li>{`Three Bedroom: ${item.three_bedroom}`}</li>
                        </ul>
                    </div>
        })
    }

    protected renderChossenStateOptions = () => {
        const data = ratesData[this.state.currentIndex || 0 ];
        return <div>
            {this.renderStateItemData(data)}
        </div>

    }

    protected ratesAsOptions = (): Array<any> => {
        return ratesData.map((item: any, index: number): React.ReactNode => {
           return <option key={index} value={`"${item.state}"`}>{`${item.state}`}</option>
        })
    }
}