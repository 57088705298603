import React from "react";
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import { Home } from "../pages/Home/Home"

export class Router extends React.Component<{}> {

    public render() : React.ReactNode {
        return (
            <BrowserRouter>
                <Switch>
                    <Route render={this.handlePageChange} exact path="/" component={Home}/>
                </Switch>
            {/*<DevTools/>*/}
            </BrowserRouter>
        )
    }

    private handlePageChange = () => {
        window.scrollTo(0, 0);
    }
    
    
}