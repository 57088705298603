import React from "react";

export class AboutNomad extends React.Component<{}> {
    public render(): React.ReactNode {
        return (
            <>
            <h2>Hello Nomadic Travelers!</h2>
            <p>
                Nomad Temporary Housing was founded by one of the pioneers of the temporary housing industry for nomadic 
                travelers like you, to provide the widest possible choice of accommodations in the world. 
                Nomad is the only independent operator of temporary apartments that combines the full service 
                of an international temporary apartment company with a myriad of locations from over 3,000 quality temporary 
                apartment providers in Nomad’s network, 22,000 different apartment properties, and 300,000 apartments. We 
                provide you with unbiased options based on the criteria that you select. Nomad places you in the best 
                accommodations for your needs, versus the competition putting you in units that best fit their needs. This 
                could be vacant units where they are still paying rent, or an owned building that is not in the location you want to be.
            </p>
            <p>
                Our full service offering begins at the time of your rental request. Nomad’s reservation team conducts a needs 
                assessment with you. We verify your specific criteria when it comes to the important decision of selecting a temporary 
                apartment. Is it location, price, or a particular apartment community? Arrival date or something else? Whatever your 
                needs may be, they drive the apartment options we provide.
            </p>
            <p>
                We feature beautiful garden style apartments in Los Angeles, sleek high rise accommodations in Shanghai, New York or 
                Vancouver, and traditional townhouses in London.
            </p>
            <p>Nomad has regional offices located in San Diego, California, Phoenix, Arizona, Hong Kong and London, GB.</p>
            </>
        );
    }
}